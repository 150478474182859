import React from "react";
import dam from "../../assets/image/icons/dam.png";


const Hero = ({ backgroundImage, backgroundImage2, className, ...rest }) => {


  return (
    <div className={className} {...rest}>

      <div className="container position-static mt-5">
        <div className="row align-items-center justify-content-center position-static">
          {/* Hero Image */}
          <div className="d-md-block col-xl-6 col-lg-6 col-md-6 col-xs-12 position-static order-2 order-sm-1" data-aos="zoom-in-up" data-aos-once="true" data-aos-delay="750">
            <h1 className="font-size-10 pr-sm-18 pr-md-8 pr-lg-0 line-height-84">
              Digital Asset Management (DAM)
              </h1>
              <h2 className="font-weight-normal font-size-6 mb-0 pr-sm-10 pr-md-10 pr-lg-0 pr-xl-18 mb-5">
              Harness the power to effortlessly organize and share your visual assets, driving seamless collaboration and boosting your brand.</h2>
              <a href="https://app.digma.io?mode=login" className="d-none d-md-inline-block mt-5 mb-10 btn btn btn-dodger-blue-2 btn-medium rounded-5 font-size-3">try for free</a>
          </div>
          <div className="d-block d-md-none p-l-10 p-5 order-1 order-sm-12">
            <div className="d-flex">
              <img className="w-100" src={dam} alt="Interactive Images" />       
            </div>
          </div>          
          {/* End Hero Image */}
          {/* Hero Content */}
          <div className="d-none d-md-block col-lg-6 col-md-6 col-sm-12 order-1 order-sm-12">
            <div
              className="mt-8 mt-lg-0 pr-md-11 pr-lg-0 pr-xl-10"
            >
              <div className="row">
                <img className="w-100" src={dam} alt="Interactive Images" />
              </div>
            </div>
          </div>
          {/* End Hero Content */}
        </div>
      </div>
    </div>
  );
};

export default Hero;

