import React from "react";


const Company = ({ className, ...rest }) => {

  return (
    <>
      {/* Company Section */}
      <div className={className} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mb-lg-8 text-center text-lg-center">
                <h2 className="h3">
                  What is Digital Asset Management?
                </h2>
                <p class="mt-10">Digital Asset Management (DAM) is a centralized and systematic approach to store, organize, retrieve, and distribute digital assets within an organization.</p>
                <h2 className="h3 mt-10">
                  Why is digital asset management important?
                </h2>
                <p class="mt-10">Digital Asset Management is essential for optimizing workflows, ensuring brand consistency, enhancing collaboration, and safeguarding valuable digital assets in today's digital landscape.</p>
                <h2 className="h3 mt-10">
                  Why should I use Digma?
                </h2>
                <p class="mt-10">High traffic volume websites support</p>
                <p class="mt-0">High quality streaming videos</p>
                <p class="mt-0">Bespoke video players</p>
              </div>
            </div>
          </div>
          {/* Brand Logos */}

        </div>
      </div>
      {/* End Company Section */}
    </>
  );
};

export default Company;
